import { Row, Col } from 'reactstrap';
import { brlMask } from '../../helpers/masks';
import { LoadingImg } from '../../helpers/images';

export function Tickets({
  tickets,
  handleAdd,
  handleRemove,
  handleBuy,
  t,
  handleBack,
}) {
  if (tickets && tickets?.length === 0) {
    return (
      <>
        <Row>
          <Col className='d-flex flex-row justify-content-center align-items-center mb-3'>
            <img
              alt='...'
              src={LoadingImg}
              className='w-25'
            />
          </Col>
        </Row>
      </>
    );
  }
  return (
    <>
      <Row>
        <Col className='mb-3'>
          <table className='table table-bordered align-middle'>
            <tbody>
              {tickets && tickets.map((ticket, index) => (
                <tr key={index}>
                  <td>{ticket.description}</td>
                  <td>{brlMask(ticket.price)}</td>
                  <td>
                    <div className='d-flex flex-row justify-content-center align-items-center gap-2'>
                      <button
                        className='btn btn-secondary w-50'
                        onClick={(e) => handleRemove(e, index)}
                      >
                        <i className='bi bi-dash'></i>
                      </button>
                      <span className='p-1'>{ticket.count}</span>
                      <button
                        className='btn btn-secondary w-50'
                        onClick={(e) => handleAdd(e, index)}
                      >
                        <i className='bi bi-plus'></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
      <Row>
        <Col className='mb-3'>
          <button
            id='btn-buy'
            className='btn btn-secondary w-100'
            onClick={handleBuy}
          >
            <i className='bi bi-cart-check me-2'></i>
            {t('tickets.fields.tobuy')}
          </button>
        </Col>
      </Row>
      <Row>
        <Col className='mb-3'>
          <button
            className='btn btn-secondary w-100'
            onClick={handleBack}
          >
            <i className='bi bi-house me-2'></i>
            {t('cart.home')}
          </button>
        </Col>
      </Row>
    </>
  );
}
