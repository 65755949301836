import { Row, Col } from 'reactstrap';
import {
  onDelete,
  alphabetMask,
  emailMask,
  mobileMask,
  badgeMask,
  cpfMask,
  cnpjMask,
} from '../../helpers/masks';
import { useEffect } from 'react';
import { cnpj } from 'cpf-cnpj-validator';

export const Attendee = ({
  index,
  attendee,
  fillAttendee,
  length,
  t,
  htmlId,
  cnpjLogin,
  cpfLogin,
}) => {
  const roles = t('attendee.fields.role.options', {
    returnObjects: true,
  });

  const departments = t('attendee.fields.department.options', {
    returnObjects: true,
  });
  const industries = t('attendee.fields.industry.options', {
    returnObjects: true,
  });

  const states = t('attendee.fields.state.options', {
    returnObjects: true,
  });
  const transfers = t('attendee.fields.transfer.options', {
    returnObjects: true,
  });
  const locations = t('attendee.fields.location.options', {
    returnObjects: true,
  });
  return (
    <>
      <h3 className='mb-3'>
        Informações do {length === 1 ? '' : `${index + 1}º`} participante
      </h3>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-name`}
          >
            <i className='bi bi-person me-2' />
            {t('attendee.fields.name')}
          </label>
          <input
            id={`attendee-${index}-name`}
            className='form-control'
            autoComplete='off'
            value={attendee.name}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('name', newValue, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-badgename`}
          >
            <i className='bi bi-person-badge me-2'></i>
            {t('attendee.fields.badgename')}
          </label>
          <input
            id={`attendee-${index}-badgename`}
            className='form-control'
            autoComplete='off'
            value={attendee.badgename}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = badgeMask(value);
              fillAttendee('badgename', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-document`}
          >
            <i className='bi bi-123 me-2'></i>
            {t('attendee.fields.document')}
          </label>
          <input
            type='email'
            id={`attendee-${index}-document`}
            className='form-control'
            name='document'
            autoComplete='off'
            value={attendee.document}
            disabled={index === 0 && cpfLogin !== ''}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = cpfMask(value);
              fillAttendee('document', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.document);
              fillAttendee('document', newValue, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-mobile`}
          >
            <i className='bi bi-telephone me-2'></i>
            {t('attendee.fields.mobile')}
          </label>
          <input
            id={`attendee-${index}-mobile`}
            className='form-control'
            autoComplete='off'
            value={attendee.mobile}
            maxLength={15}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = mobileMask(value);
              fillAttendee('mobile', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.mobile);
              fillAttendee('mobile', newValue, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-state`}
          >
            <i className='bi bi-geo-alt me-2'></i>
            {t('attendee.fields.state.name')}
          </label>
          <select
            id={`attendee-${index}-state`}
            className='form-control'
            autoComplete='off'
            value={attendee.state}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value;
              fillAttendee('state', newValue, index);
            }}
          >
            {states.map((state, index) => (
              <option
                key={index}
                value={state.code}
              >
                {state.name}
              </option>
            ))}
          </select>
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-city`}
          >
            <i className='bi bi-geo-alt me-2' />
            {t('attendee.fields.city')}
          </label>
          <input
            id={`attendee-${index}-city`}
            className='form-control'
            autoComplete='off'
            value={attendee.city}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value;
              fillAttendee('city', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-transfer`}
          >
            <i className='bi bi-bus-front me-2'></i>
            {t('attendee.fields.transfer.name')}
          </label>
          <select
            id={`attendee-${index}-transfer`}
            className='form-control mb-2'
            autoComplete='off'
            value={attendee.transfer}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value;
              fillAttendee('transfer', newValue, index);
            }}
          >
            {transfers.map((transfer, index) => (
              <option
                key={index}
                value={transfer.code}
              >
                {transfer.name}
              </option>
            ))}
          </select>
          <span className='form-check-label '>
            <i>{t('attendee.fields.transfer.title')}</i>
          </span>
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-location`}
          >
            <i className='bi bi-geo-alt me-2'></i>
            {t('attendee.fields.location.name')}
          </label>
          <select
            id={`attendee-${index}-location`}
            className='form-control'
            autoComplete='off'
            value={attendee.location}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value;
              fillAttendee('location', newValue, index);
            }}
          >
            {locations.map((location, index) => (
              <option
                key={index}
                value={location.code}
              >
                {location.name}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-email`}
          >
            <i className='bi bi-envelope me-2'></i>
            {t('attendee.fields.email')}
          </label>
          <input
            type='email'
            id={`attendee-${index}-email`}
            className='form-control'
            name='email'
            autoComplete='off'
            value={attendee.email}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = emailMask(value);
              fillAttendee('email', newValue, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-role`}
          >
            <i className='bi bi-card-checklist me-2'></i>
            {t('attendee.fields.role.name')}
          </label>
          <select
            id={`attendee-${index}-role`}
            className='form-control'
            autoComplete='off'
            value={attendee.role}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value;
              fillAttendee('role', newValue, index);
            }}
          >
            <option value={''}>{t('attendee.fields.role.select')}</option>
            {roles.map((country, index) => (
              <option
                key={index}
                value={country.code}
              >
                {country.name}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-company`}
          >
            <i className='bi bi-building me-2'></i>
            {t('attendee.fields.company')}
          </label>
          <input
            id={`attendee-${index}-company`}
            className='form-control'
            autoComplete='off'
            value={attendee.company}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('company', value, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-tradename`}
          >
            <i className='bi bi-building me-2'></i>
            {t('attendee.fields.tradename')}
          </label>
          <input
            id={`attendee-${index}-tradename`}
            className='form-control'
            autoComplete='off'
            value={attendee.tradename}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('tradename', value, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
          hidden={cpfLogin !== ''}
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-companydocument`}
          >
            <i className='bi bi-123 me-2'></i>
            {t('attendee.fields.companydocument')}
          </label>
          <input
            id={`attendee-${index}-companydocument`}
            className='form-control'
            autoComplete='off'
            value={attendee.companydocument}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = cnpjMask(value);
              fillAttendee('companydocument', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.document);
              fillAttendee('document', newValue, index);
            }}
            disabled={cnpjLogin !== ''}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-department`}
          >
            <i className='bi bi-building me-2'></i>
            {t('attendee.fields.department.name')}
          </label>
          <select
            id={`attendee-${index}-department`}
            className='form-control'
            autoComplete='off'
            value={attendee.department}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value;
              fillAttendee('department', newValue, index);
            }}
          >
            <option value={''}>{t('attendee.fields.department.select')}</option>
            {departments.map((department, index) => (
              <option
                key={index}
                value={department.code}
              >
                {department.name}
              </option>
            ))}
          </select>
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-industry`}
          >
            <i className='bi bi-building me-2'></i>
            {t('attendee.fields.industry.name')}
          </label>
          <select
            id={`attendee-${index}-industry`}
            className='form-control'
            autoComplete='off'
            value={attendee.industry}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value;
              fillAttendee('industry', newValue, index);
            }}
          >
            <option value={''}>{t('attendee.fields.industry.select')}</option>
            {industries.map((industry, index) => (
              <option
                key={index}
                value={industry.code}
              >
                {industry.name}
              </option>
            ))}
          </select>
        </Col>
        <Col
          md={6}
          className='mb-3'
          hidden={attendee.industry !== 'Outros setores'}
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-area`}
          >
            <i className='bi bi-building me-2'></i>
            {t('attendee.fields.area')}
          </label>
          <input
            id={`attendee-${index}-area`}
            className='form-control'
            autoComplete='off'
            value={attendee.area}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('area', value, index);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <div className='form-check'>
            <input
              id={`attendee-${index}-information`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.information}
              onChange={(e) => {
                fillAttendee('information', !attendee.information, index);
              }}
            ></input>
            <label className='form-check-label'>
              <p className='text-justify'>
                {t('attendee.fields.information.start')}{' '}
                <a
                  href={t('attendee.fields.information.link_url')}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  {t('attendee.fields.information.link_text')}
                </a>{' '}
                {t('attendee.fields.information.end')}
              </p>
            </label>
          </div>
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <div className='form-check'>
            <input
              id={`attendee-${index}-lgpd`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.lgpd}
              onChange={(e) => {
                fillAttendee('lgpd', !attendee.lgpd, index);
              }}
            ></input>
            <label className='form-check-label'>
              <p className='text-justify'>
                {t('attendee.fields.lgpd.start')}{' '}
                <a
                  href={t('attendee.fields.lgpd.link_url')}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  {t('attendee.fields.lgpd.link_text')}
                </a>{' '}
                {t('attendee.fields.lgpd.end')}
              </p>
            </label>
          </div>
        </Col>
      </Row>
    </>
  );
};
