export default function Steps({
  handlePrev,
  handleNext,
  textPrev,
  textNext,
  disableNext = false,
}) {
  return (
    <>
      <section className='d-flex flex-row justify-content-end mt-3 mb-3 gap-1'>
        <button
          type='button'
          className='btn btn-secondary mw-25'
          onClick={handlePrev}
        >
          {textPrev}
        </button>
        <button
          type='button'
          className='btn btn-secondary mw-25'
          onClick={handleNext}
          disabled={disableNext}
        >
          {textNext}
        </button>
      </section>
    </>
  );
}
