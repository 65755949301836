import './styled.css';
import Banner from '../../assets/images/banner.webp';
import LogoHeader from '../../assets/images/HeaderLogo.webp';
import HeaderBalls from '../../assets/images/Header.webp'

export default function Header() {
  return (
    <div className='header'>
      <img
        alt='Brasil em código'
        src={LogoHeader}
        className='header-logo'
      />

      <h2 className='header-text'>
        INGRESSOS
      </h2>

      <img
        alt='Brasil em código'
        src={HeaderBalls}
        className='image-orange-balls'
      />
    </div>
  );
}

export function BannerLogin () {
  return (
    <div className='d-flex justify-content-center mb-5 mt-5'>
      <img
        alt='Banner do evento'
        src={Banner}
        className='header-img'
      />
    </div>
  );
}
