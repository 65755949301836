import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { documentMask, onDelete } from '../../helpers/masks';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import Header, { BannerLogin } from '../../components/header';
import { Alert } from '../../components/modal';

import { useError } from '../../hooks/useError';

import { TransactionContext } from '../../contexts/TransactionContext';
import { isEmpty } from '../../helpers/validation';

import { getTickets } from '../../services/product';

import { EVENTID } from '../../helpers/constants';
import Footer from '../../components/footer';

export default function Login() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [associated, setAssociated] = useState(false);
  const [documentNumber, setDocumentNumber] = useState('');

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const { setNewTickets, firstStep, setCnpjLogin, setCpfLogin, setMaxCount } =
    useContext(TransactionContext);
  const { goToFieldWithError } = useError();

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    goToFieldWithError('login-document');
  };

  const handleAssociated = async (e) => {
    e.preventDefault();

    if (isEmpty(documentNumber)) {
      return;
    }

    const currentDocument = documentNumber.replace(/\D/g, '');
    const validated = cpf.isValid(currentDocument)
      ? true
      : cnpj.isValid(currentDocument)
      ? true
      : false;

    if (validated) {
      const response = await getTickets({
        document: documentNumber,
        eventId: EVENTID,
      });
      if (!response?.associated) {
        setCnpjLogin('');
        setNewTickets([]);
        setMessage(t('login.errors.notassociated'));
        openModal();
      } else {
        if (cnpj.isValid(currentDocument)) {
          setCnpjLogin(documentNumber);
          setMaxCount(10);
        }
        if (cpf.isValid(currentDocument)) {
          //setCpfLogin(documentNumber);
          setMaxCount(10);
        }
        const tickets = response?.products?.map((ticket) => ({
          ...ticket,
          count: 0,
        }));
        setNewTickets(tickets);
        navigate('/carrinho');
      }
    } else {
      setMessage(t('login.errors.document'));
      openModal();
    }
  };

  const handleNotAssociated = async (e) => {
    const response = await getTickets({
      document: '',
      eventId: EVENTID,
    });
    const tickets = response?.products?.map((ticket) => ({
      ...ticket,
      count: 0,
    }));

    setNewTickets(tickets);
    navigate('/carrinho');
  };

  const modal = (
    <Alert
      isOpen={open}
      toggle={closeModal}
      message={message}
    />
  );

  const options = (
    <>
      <div className='container'>
        <div className='d-flex gap-3 justify-content-center col mb-4'>
          <button
            className='btn btn-secondary btn-gs1-login'
            onClick={(e) => setAssociated(true)}
          >
            <i className='bi bi-person-vcard-fill me-2' />
            {t('login.buttons.associated')}
          </button>

          <button
            className='btn btn-secondary btn-gs1-login'
            onClick={handleNotAssociated}
          >
            <i className='bi bi-person-fill me-2' />
            {t('login.buttons.notassociated')}
          </button>
        </div>
      </div>
    </>
  );

  const associatedLogin = (
    <>
      <form onSubmit={handleAssociated}>
        <Row className='w-100 justify-content-center'>
          <Col className='w-100 justify-content-center col-md-3 mb-3'>
            <label
              htmlFor='login-document'
              className='form-label'
            >
              {t('login.fields.document')}
            </label>
            <input
              id='login-document'
              className='form-control'
              style={{ width: '200px' }}
              type='text'
              autoComplete='off'
              value={documentNumber}
              onChange={(e) => {
                const { value } = e.target;
                let newValue = documentMask(value);
                setDocumentNumber(newValue);
              }}
              onKeyDown={(e) => {
                const newValue = onDelete(e, documentNumber);
                setDocumentNumber(newValue);
              }}
            />
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col className='col-auto mb-4'>
            <button
              type='submit'
              className='btn btn-secondary'
            >
              <i className='bi bi-box-arrow-in-right me-2' />
              {t('login.buttons.enter')}
            </button>
          </Col>
        </Row>
      </form>
    </>
  );

  useEffect(() => {
    document.title = t('title');
    firstStep();
    setCnpjLogin('');
    setCpfLogin('');
    setMaxCount(10);
    setNewTickets([]);
  }, [open, t]);

  return (
    <main className='container-login'>
      {/* <Header /> */}
      {modal}
      <BannerLogin />
      {!associated && options}
      {associated && associatedLogin}
      {/* <Footer /> */}
    </main>
  );
}
