import { brlMask } from '../../helpers/masks';

export default function Cart({ cart, amount, t }) {
  return (
    <>
      <ul className='list-group ps-3 pe-3'>
        {cart.map((ticket) => (
          <li
            className='list-group-item d-flex justify-content-between lh-sm gap-5'
            key={ticket.id}
          >
            <div>
              <h6 className='my-0'>{ticket.description}</h6>
              <small className='text-body-secondary'>
                {ticket.count} x {brlMask(ticket.price)}
              </small>
            </div>
            <span className='text-body-secondary'>
              {brlMask(Math.round(ticket.count * ticket.price * 100) / 100)}
            </span>
          </li>
        ))}
        <li
          key={-1}
          className='list-group-item d-flex justify-content-between mb-3'
        >
          {amount !== null && (
            <>
              <span>Total</span>
              <strong>{brlMask(amount)}</strong>
            </>
          )}
        </li>
      </ul>
    </>
  );
}
