const attendeeDefault = {
  name: '',
  badgename: '',
  document: '',
  mobile: '',
  email: '',
  role: '',
  company: '',
  tradename: '',
  companydocument: '',
  department: '',
  industry: '',
  information: false,
  lgpd: false,
  area: '',
  state: '',
  city: '',
  transfer: '',
  location: '',
};

const cctokenDefault = {
  number: '',
  name: '',
  country: '',
  expireAt: '',
  code: '',
  installments: 1,
};

const customerDefault = {
  name: '',
  email: '',
  document: '',
  address: '',
};

const transactionDefault = {
  promoCode: 'NOCODE',
  eventId: process.env.REACT_APP_EVENT_ID,
  eventCode: 128729,
  attendees: [],
  products: [],
  paymentType: 'pix',
  customer: null,
  token: '',
  installments: 1,
};
const transactionResponseDefault = { success: false, data: null };

const loginDefault = {
  document: '',
  eventId: process.env.REACT_APP_EVENT_ID,
};

export {
  attendeeDefault,
  cctokenDefault,
  customerDefault,
  transactionDefault,
  transactionResponseDefault,
  loginDefault,
};
