import {
  isCVV,
  isCreditCardNumber,
  isEmpty,
  isEmail,
  isCPForCNPJ,
  isMobile,
  isCPF,
  isCNPJ,
} from '../helpers/validation';

export const useError = () => {
  const goToFieldWithError = (id) => {
    const element = document.getElementById(id);
    let offset = 150;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
    element.focus();
  };

  const attendeeError = {
    name: (attendee) => !isEmpty(attendee.name),
    badgename: (attendee) => !isEmpty(attendee.badgename),
    document: (attendee) => isCPF(attendee.document),
    mobile: (attendee) => isMobile(attendee.mobile),
    state: (attendee) => !isEmpty(attendee.state),
    city: (attendee) => !isEmpty(attendee.city),
    transfer: (attendee) => !isEmpty(attendee.transfer),
    location: (attendee) => !isEmpty(attendee.location),
    email: (attendee) => isEmail(attendee.email),
    role: (attendee) => !isEmpty(attendee.role),
    company: (attendee) => !isEmpty(attendee.company),
    tradename: (attendee) => !isEmpty(attendee.tradename),
    companydocument: (attendee) => isCNPJ(attendee.companydocument),
    department: (attendee) => !isEmpty(attendee.department),
    industry: (attendee) => !isEmpty(attendee.industry),
    area: (attendee) => {
      if (attendee.industry === 'Outros setores') {
        return !isEmpty(attendee.area);
      }
      return true;
    },
    information: (attendee) => attendee.information,
    lgpd: (attendee) => attendee.lgpd,
  };

  const cctokenError = {
    number: (cctoken) => isCreditCardNumber(cctoken.number),
    name: (cctoken) => !isEmpty(cctoken.name),
    country: (cctoken) => !isEmpty(cctoken.country),
    expireAt: (cctoken) => !isEmpty(cctoken.expireAt),
    code: (cctoken) => isCVV(cctoken.code),
  };

  const customerError = {
    name: (customer) => !isEmpty(customer.name),
    document: (customer) => isCPForCNPJ(customer.document),
    email: (customer) => isEmail(customer.email),
    address: (customer) => !isEmpty(customer.address),
  };
  return {
    attendeeError,
    customerError,
    cctokenError,
    goToFieldWithError,
  };
};
